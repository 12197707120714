<template>
  <v-flex fluid>
    <div class="row">
      <div>
        <v-speed-dial
          v-model="fab"
          small
          :top="top"
          :bottom="bottom"
          :right="right"
          :left="left"
          :direction="direction"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-plus </v-icon>
            </v-btn>
          </template>
          <v-btn fab dark small color="red" @click="imprimir(false)">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <!-- <v-btn
          fab
          dark
          small
          color="green"
          @click="imprimir(true)"
          v-if="tipo != 'nuevo'"
        >
          <v-icon dark> mdi-cloud-upload </v-icon>
        </v-btn> -->
          <!-- <v-btn fab dark small color="indigo">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4">
          <div class="col-12">
            <h4>Datos del Casillero</h4>
            <v-text-field
              hide-details
              v-model="instructivoCasillero.expediente"
              label="Expediente"
              readonly
            ></v-text-field>
            <v-text-field
              hide-details
              v-model="instructivoCasillero.volumen"
              label="Volumen"
              readonly
            ></v-text-field>
            <v-text-field
              hide-details
              v-model="instructivoCasillero.peso"
              label="Peso"
              readonly
            ></v-text-field>
            <v-text-field
              hide-details
              v-model="instructivoCasillero.fechaSalida"
              type="date"
              readonly
              label="Fecha Salida"
            ></v-text-field>
            <v-text-field
              hide-details
              v-model="instructivoCasillero.fechaRegistro"
              type="date"
              readonly
              label="Fecha Registro"
            ></v-text-field>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8">
          <div class="col-12">
            <h4 class="mb-1 ml-1">Cotizaciones:</h4>
            <v-simple-table dense>
              <thead>
                <th>Fecha</th>
                <th>Status</th>
                <th>Vendedor</th>
                <th>Código</th>
                <th>Cliente</th>
                <th>Teléfono</th>
                <th>Mercancia</th>
                <th>Peso</th>
                <th>Volumen</th>
                <th>Venta</th>
              </thead>
              <tbody>
                <tr
                  v-for="quote in instructivoCasillero.listado_quote"
                  :key="quote.id"
                >
                  <td>{{ quote.fecha }}</td>
                  <td>{{ quote.statusName }}</td>
                  <td>{{ quote.entitieNames }}</td>
                  <td>{{ quote.code }}</td>
                  <td>{{ quote.cliente }}</td>
                  <td>{{ quote.telefono }}</td>
                  <td>{{ quote.descripcionMercancia }}</td>
                  <td>{{ quote.peso }}</td>
                  <td>{{ quote.volumen }}</td>
                  <td>{{ quote.ganancia }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="col-12">
            <h4 class="mb-1 ml-1">Costo</h4>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th>Proveedor</th>
                  <th>Concepto</th>
                  <th>Monto</th>
                  <th>IGV</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="costo in instructivoCasillero.costo"
                  :key="costo.concepto"
                >
                  <td :rowspan="costo.row" v-if="costo.row">
                    {{ costo.proveedor }}
                  </td>
                  <td>{{ costo.concepto }}</td>
                  <td>{{ currencyFormat(costo.total) }}</td>
                  <td>{{ currencyFormat(costo.impuesto) }}</td>
                  <td>{{ currencyFormat(costo.impuesto + costo.total) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>
import { mapActions, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  mixins: [funcion],
  name: "instructivoCasilleroComponet",
  data() {
    return {
      cabQuote: [
        { value: "fecha", text: "Fecha" },
        { value: "statusName", text: "Status" },
        { value: "entitieNames", text: "Vendedor" },
        { value: "code", text: "Código" },
        { value: "cliente", text: "Cliente" },
        { value: "telefono", text: "Teléfono" },
        { value: "descripcionMercancia", text: "Mercancia" },
        { value: "peso", text: "Peso" },
        { value: "volumen", text: "Volumen" },
        { value: "ganancia", text: "Venta" },
      ],
      // boton
      direction: "right",
      fab: false,
      fling: true,
      hover: false,
      tabs: null,
      top: true,
      right: true,
      bottom: false,
      left: true,
      transition: "slide-y-reverse-transition",
    };
  },
  computed: { ...mapState(["instructivoCasillero"]) },
  mounted() {
    this.instructivoCasillero.costo = this.generarDatosCostos(
      this.instructivoCasillero.costo
    );
  },
  methods: {
    ...mapActions(["exportarInstructivoCasillero"]),
    generarDatosCostos(datos) {
      var proveedor = "";
      var nuevoProvedor = "";
      var cost = [];
      var nuevoFlag = false;
      datos.forEach((element) => {
        if (element.proveedor != proveedor) {
          proveedor = element.proveedor;
        }

        if (!nuevoProvedor) {
          nuevoProvedor = element.proveedor;
          nuevoFlag = true;
        } else if (nuevoProvedor === element.proveedor) {
          nuevoFlag = false;
        } else {
          nuevoProvedor = element.proveedor;
          nuevoFlag = true;
        }

        const row = this.calcularRow(datos, proveedor);
        cost.push({
          proveedor: nuevoFlag ? nuevoProvedor : "",
          row: nuevoFlag ? row : "",
          concepto: element.concepto,
          total: element.total,
          impuesto: element.impuesto,
        });
      });
      return cost;
    },
    calcularRow(datos, proveedor) {
      var cantidad = datos.filter(
        (v) => v.proveedor == proveedor && v.proveedor
      ).length;

      return cantidad;
    },
    async imprimir() {
      let data = {
        id: this.$route.params.id,
        expediente: this.instructivoCasillero.expediente,
      };
      this.exportarInstructivoCasillero(data);
      // this.actualizarCasillero()
    },
  },
};
</script>

<style></style>
